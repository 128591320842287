<template>
  <div class="history-wrap" >
    <div class="my-chart"  v-if="loading"/>

    <Table
      :columns="enumColumns[route.query.type]"
      :dataSource="source"
      :title="title"
      :isZebra="true"
    />
  </div>
</template>

<script>
import { onMounted, inject, getCurrentInstance, ref } from "vue";
import Table from "../components/Table.vue";
import { useRoute, useRouter } from "vue-router";
import { Toast } from "vant";

export default {
  components: {
    Table,
  },
  setup(_, context) {
    let echarts = inject("ec");
    const route = useRoute();
    const router = useRouter();

    const source = ref([]);
    const data = ref([]);
    const seriesRef = ref({});
    const loading = ref(false)

    const { proxy } = getCurrentInstance();

    const renderChart = () => {
      let myChart = echarts.init(document.querySelector(".my-chart"));

      let option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          bottom: "0",
          data: seriesRef.value.map((v) => v.name),
        },
        toolbox: {
          feature: {
            // saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: data.value,
        },
        yAxis: {
          type: "value",
        },
        series: seriesRef.value,
      };

      myChart.setOption(option);
    };

    const userInfo = JSON.parse(localStorage.getItem("user_info"));

    const getHomeList = async () => {
      let res = await proxy.axios.get(
        "/api/base/homeHistory?communityId=" + userInfo[0].communityId
      );

      if (res.code == 200 && res.msg == "success") {
        source.value = res.data;
        context.emit('changeLoading', false);
        loading.value = true;

        let temp = res.data.sort((a, b) => (a.day < b.day ? -1 : 0));

        let tempData = [],
          aveTemp = [],
          aveFlow = [];

        temp.map((v) => {
          tempData.push(v.day.substr(5));
          aveTemp.push(v.averageTemperature);
          aveFlow.push(v.averageFlow);
        });

        data.value = tempData;

        seriesRef.value = [
          { name: "平均温度", type: "line", data: aveTemp },
          { name: "平均流量", type: "line", data: aveFlow },
        ];

        setTimeout(() => {
          renderChart();
        },0)
      } else {
        Toast("数据获取失败，正在重新加载！");
        getHomeList();
      }
    };

    const getHouseholdList = async () => {
      let res = await proxy.axios.get(
        "/api/base/communityUserDetail?deviceId=" + route.query.deviceId
      );

      if (res.code == 200 && res.msg == "success") {
        source.value = res.data;
        loading.value = true;
        context.emit('changeLoading', false);

        let temp = res.data.sort((a, b) =>
          a.createTime < b.createTime ? -1 : 0
        );

        let tempData = [],
          aveTemp = [],
          aveOut = [];

        temp.map((v) => {
          tempData.push(v.createTime.substr(5, 5));
          aveTemp.push(v.inputTemperature);
          aveOut.push(v.outputTemperature);
        });

        data.value = tempData;
        seriesRef.value = [
          { name: "平均温度", type: "line", data: aveTemp },
          { name: "平均回水温度", type: "line", data: aveOut },
        ];

        setTimeout(() => {
          renderChart();
        },0)
      } else {
        Toast("数据获取失败，正在重新加载！");
        getHouseholdList();
      }
    };

    const getWarnList = async () => {
      let res = await proxy.axios.get(
        "/api/base/warningHistory?communityId=" + userInfo[0].communityId
      );

      if (res.code == 200 && res.msg == "success") {
        source.value = res.data;
        loading.value = true;
        context.emit('changeLoading', false);

        let temp = res.data.sort((a, b) =>
          a.createTime < b.createTime ? -1 : 0
        );

        let tempData = [],
          aveTemp = [],
          aveHigh = [],
          aveLow = [];

        temp.map((v) => {
          tempData.push(v.createTime.substr(5, 5));
          aveTemp.push(v.temperature);
          aveHigh.push(v.highLevel);
          aveLow.push(v.lowLevel);
        });

        data.value = tempData;
        seriesRef.value = [
          { name: "当前温度", type: "line", data: aveTemp },
          { name: "最高温度", type: "line", data: aveHigh },
          { name: "最低温度", type: "line", data: aveLow },
        ];

        setTimeout(() => {
          renderChart();
        },0)
      } else {
        Toast("数据获取失败，正在重新加载！");
        getWarnList();
      }
    };

    router.beforeEach(() => {});

    onMounted(() => {
      loading.value = false
      context.emit('changeLoading', true);

      const fnEnum = {
        home: getHomeList,
        household: getHouseholdList,
        warn: getWarnList,
      };

      fnEnum[route.query.type]();
    });

    return {
      source,
      route,
      loading
    };
  },
  data() {
    return {
      title: "详细数据",
      enumColumns: {
        home: [
          {
            title: "时间",
            key: "day",
          },
          {
            title: "平均流量",
            key: "averageFlow",
          },
          {
            title: "平均温度",
            key: "averageTemperature",
          },
          {
            title: "平均回水温度",
            key: "averageOutTemperature",
          },
          {
            title: "平均热量",
            key: "averageCaloric",
          },
        ],
        household: [
          {
            title: "时间",
            key: "createTime",
          },
          {
            title: "平均流量",
            key: "flow",
            render: (val, _, context) => {
              return `${val}${context.flowUnit}`;
            },
          },
          {
            title: "平均温度",
            key: "inputTemperature",
          },
          {
            title: "平均回水温度",
            key: "outputTemperature",
          },
        ],
        warn: [
          {
            title: "时间",
            key: "createTime",
          },
          {
            title: "当前温度",
            key: "temperature",
          },
          {
            title: "最高温度",
            key: "highLevel",
          },
          {
            title: "最低温度",
            key: "lowLevel",
          },
        ],
      },
    };
  },
};
</script>

<style scoped lang="scss">
.history-wrap {
  width: 100%;
  .my-chart {
    width: 100%!important;
    height: 3rem;
    margin: 0.1rem 0;
  }
}
</style>
